import React from 'react';
import SEO from '../components/SEO';

function TermsOfService() {
  return (
    <>
    <SEO 
  title="Terms of Service - Directory Maker"
  description="Directory Maker's terms of service. Understand our terms and conditions for using the directory website solution."
  canonicalUrl="/terms-of-service"
  keywords="directory maker terms, directory software conditions, usage terms"
/>
    <div className="container mx-auto px-4 py-20">
      <h1 className="text-4xl font-bold mb-8">Directory Maker Terms and Conditions</h1>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">1. Introduction</h2>
      <p className="mb-4">
        Welcome to Directory Maker! By accessing and using our Directory Maker product and website, you agree to comply with and be bound by the following terms and conditions. Please review them carefully before using our services. If you do not agree to these terms, you should not use Directory Maker.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">2. Acceptance of Terms</h2>
      <p className="mb-4">
        By purchasing, accessing, or using Directory Maker, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions, as well as any applicable laws and regulations. These terms apply to all users of Directory Maker, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">3. Product License and Usage</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">License Grant</h3>
      <p className="mb-4">
        Upon purchase, you are granted a non-exclusive, non-transferable license to use Directory Maker for your personal or commercial projects. This license allows you to modify, customize, and deploy Directory Maker as needed for your specific use case.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">No Resale or Redistribution</h3>
      <p className="mb-4">
        You may not resell, redistribute, or otherwise share the Directory Maker codebase or any derivative works based on it, whether modified or unmodified. The license granted to you is for your own use only and is non-transferable.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Customization</h3>
      <p className="mb-4">
        While you are allowed to customize Directory Maker to fit your needs, you may not redistribute your customized version in its original or any substantially similar form.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">4. Payments and Refund Policy</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Payments</h3>
      <p className="mb-4">
        All payments for Directory Maker are processed securely through our payment gateway. By making a payment, you agree to provide accurate and complete payment information.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">No Refunds</h3>
      <p className="mb-4">
        Due to the nature of digital products, all sales of Directory Maker are final. We do not offer refunds under any circumstances. We encourage you to review all available information and documentation before making your purchase to ensure Directory Maker meets your needs.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">5. Feature Requests and Support</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Feature Requests</h3>
      <p className="mb-4">
        While we are continuously improving Directory Maker and welcome suggestions for new features, we do not take on custom feature requests or provide bespoke development services for individual clients. We may, at our discretion, incorporate user feedback into future updates.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Support</h3>
      <p className="mb-4">
        We are committed to helping you successfully use Directory Maker. Our support is limited to assisting you with setup, usage, and addressing any issues directly related to the functionality of Directory Maker. Please note that we do not offer customization services or work directly on clients' projects.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">6. Intellectual Property</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Ownership</h3>
      <p className="mb-4">
        Directory Maker, including all code, design, and intellectual property associated with the product, remains the property of the original creators. You do not acquire ownership of any part of Directory Maker through your purchase.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Trademarks</h3>
      <p className="mb-4">
        Any logos, trademarks, or service marks associated with Directory Maker are the property of their respective owners. You may not use these trademarks without the express written consent of the trademark owner.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">7. Limitation of Liability</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Disclaimer</h3>
      <p className="mb-4">
        Directory Maker is provided "as is" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the product will be error-free, secure, or meet your specific requirements.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Limitation of Liability</h3>
      <p className="mb-4">
        In no event shall the creators of Directory Maker be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of Directory Maker, whether based on warranty, contract, tort, or any other legal theory.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">8. Termination</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Termination of License</h3>
      <p className="mb-4">
        We reserve the right to terminate your license to use Directory Maker at any time if you violate these terms and conditions. Upon termination, you must cease all use of Directory Maker and destroy any copies of the software in your possession.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">9. Modifications to Terms</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Changes to Terms</h3>
      <p className="mb-4">
        We reserve the right to update or modify these terms and conditions at any time without prior notice. Your continued use of Directory Maker following any changes constitutes your acceptance of the new terms. We encourage you to review these terms periodically.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">10. Governing Law</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Jurisdiction</h3>
      <p className="mb-4">
        These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which the creators of Directory Maker are based, without regard to its conflict of law principles.
      </p>
    </div>
    </>
  );
}

export default TermsOfService;

