import React from 'react';
import FeaturesSection from '../components/FeaturesSection';
import SEO from '../components/SEO';

function Features() {
  return (
    <>
    <SEO 
  title="Features - Directory Maker | Enterprise-Grade Directory Solution"
  description="Explore Directory Maker's powerful features: advanced search system, secure authentication, real-time updates, complete admin dashboard, and monetization tools for your directory website."
  canonicalUrl="/features"
  keywords="directory website features, business directory software features, listing website functionality, directory cms features"
/>
    <main>
      <h1>Directory Maker Features</h1>
      <FeaturesSection />
    </main>
    </>
  );
}

export default Features;